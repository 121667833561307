<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <!--{{user.name}}-->
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.tags.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="tag.title" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="tag.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Tag from "../../../models/Tag";

    export default {
        name: "tags-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                tag: {},
            }
        },
        async mounted() {
            const tagId = this.$route.params.id
            this.tag = await Tag.find(tagId)
        },
    }
</script>

<style lang="scss">
</style>
